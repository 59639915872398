// import { Button } from 'antd';
import { NextPage } from 'next';

// import Message from './template';

const NotFoundError: NextPage = () => (
  <div>Cannot find the Page</div>

  //   <Message
  //     title="Are you lost?"
  //     subTitle="Try returning to the GrowFlow Wholesale/Retail application and navigate to Insights again."
  //     icon="404"
  //   >
  //     <Button type="primary">Back Home</Button>
  //   </Message>
);

export default NotFoundError;
